import React from "react"
import styled from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import { ButtonGhostInverse } from "../Button"
import { Primary } from "../Section"
import ContactHero from "./ContactHero"
import HandSpoon from "../../images/img-hand-spoon.jpg"
import { BackgroundSectionStyle } from "../Section/backgroundSection.css.js"

const Label = styled.label`
  display: block;
`
const Input = styled.input`
  width: 100%;
  background-color: ${props => props.theme.gallery};
  padding: 0.5rem;
  border-radius: 2px;
  display: block;
  margin-bottom: 1rem;
  border-style: solid;
`
const TextArea = styled.textarea`
  display: block;
  background-color: ${props => props.theme.gallery};
  border-style: solid;
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
`
const StyledContactHero = styled(ContactHero)`
  ${BackgroundSectionStyle};
  padding-top: 10rem;
  min-height: ${props => props.backgroundHeight}px;
  text-align: ${props => (props.centered ? "center" : "initial")};
`
const Title = styled.h1`
  color: ${props => props.theme.black};
`
const ImgHandSpoon = styled.img`
  width: 40%;
`

const Contact = ({ sectionImg }) => {
  return (
    <section>
      <StyledContactHero backgroundHeight={550} centered>
        <Title>The Road to Incredible Partnerships!</Title>
      </StyledContactHero>
      <Primary>
        <Row>
          <Col xs={12} md={6}>
            <h3>Contact Dr. Joyner </h3>
            <p>
              <strong>E-Mail:</strong> dr.joyner@joynermd.com
            </p>
            <ImgHandSpoon src={HandSpoon} alt="Hand and spoon statute" />
          </Col>
          <Col xs={12} md={6}>
            <form
              name="contact"
              method="post"
              action="/thanks"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <Label>Your Name:* </Label>
              <Input type="text" name="name" id="name" required />
              <Label>Your Email:* </Label>
              <Input type="email" name="email" id="email" required />
              <Label>Message:* </Label>
              <TextArea name="message" id="message" />
              <ButtonGhostInverse type="submit" required>
                Send your message
              </ButtonGhostInverse>
            </form>
          </Col>
        </Row>
      </Primary>
    </section>
  )
}

export default Contact
